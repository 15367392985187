import { template as template_e5695cae1d124579bd46942d4e925c2f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e5695cae1d124579bd46942d4e925c2f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
