import { template as template_a0e0e53d51b3483dbddbfd1b66cdf617 } from "@ember/template-compiler";
const WelcomeHeader = template_a0e0e53d51b3483dbddbfd1b66cdf617(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
