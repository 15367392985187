import { template as template_fc891d77fde44cb8922786dd161e0e16 } from "@ember/template-compiler";
const FKLabel = template_fc891d77fde44cb8922786dd161e0e16(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
